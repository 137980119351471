import AboutCommand from "./about";
import BlogCommand from "./blog";
import ClearCommand from "./clear";
import ContactCommand from "./contact";
import CourseCommand from "./course";
import CVCommand from "./cv";
import HelpCommand from "./help";
import ManCommand from "./man";

export {
    AboutCommand,
    BlogCommand,
    ClearCommand,
    ContactCommand,
    CourseCommand,
    CVCommand,
    HelpCommand,
    ManCommand,
}