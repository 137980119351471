import CourseCommand from "./course";
import CVCommand from "./cv";
import DefaultCommand from "./default";
import ManCommand from "./man";
import { EscSeq } from "../const";

export default class AboutCommand extends DefaultCommand {
    static name = "about";
    static usage = "about [options]";
    static description = `Prints short information about the author.`;

    run(term) {
        this.validate();

        if (this.shortFlags && this.shortFlags[0] === "-h") {
            ManCommand.printHelp(term, AboutCommand);
            return
        }

        term.printText(`My name is Alexander Borisov and I'm a software architect and language-agnostic software engineer.
I'm interested in go, k8s and ML.
To get more details about my professional experience use ${EscSeq.colorMagenta}${CVCommand.name}${EscSeq.resetStyles} command.
Also I'm reading online-course "Dive into the Go". More details here ${EscSeq.colorMagenta}${CourseCommand.name}${EscSeq.resetStyles}.
`);
    }
}
